import {
  Button,
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import PropTypes, { bool, string } from 'prop-types';
import styled from 'styled-components';

import { BfeGrid } from '../../styles/grid';
import ClientDate, { ClientDateTime } from '../ClientDate';
import Container from '../Container';
import Webinars from '../home_page/Webinars';
import {
  StyledPageTitle,
  TitleHero,
  TitleWithImageContainer,
} from '../home_page/styles';
import { ShowProps } from './types';

const MiddleSectionContainer = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 1.5rem 0;
  margin: 1.5rem 0;
`;

export const TradingChallengeTitleContainer = styled.div`
  background-image: ${({ img }) => `url("${img}")`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding: 1rem 25rem 3rem 0.5rem;
  margin: 0 auto;
  max-width: 1440px;
`;

export const StatCol = styled(Col)`
  text-align: center;
  margin-top: 1rem;
  p {
    margin-top: 1rem;
  }
`;

export const TradingChallengeTitleCard = ({
  title,
  description,
  img,
  registrationLink,
  registrationPeriodOpen,
  registrationEnded,
  hasLeaderboards,
  leaderboardLink,
  hideActionButton,
  tradingChallengeId,
  currentUser,
  certificatePath,
}) => {
  const showCertificateButton =
    currentUser?.type !== 'Professor' && certificatePath;

  let ActionButton;
  if (hideActionButton) {
    ActionButton = null;
  } else if (registrationPeriodOpen) {
    ActionButton = (
      <Button kind="primary" className="mr-3" as={'a'} href={registrationLink}>
        Register
      </Button>
    );
  } else if (hasLeaderboards) {
    ActionButton = (
      <Button
        kind={showCertificateButton ? 'tertiary' : 'primary'}
        style={{ color: showCertificateButton ? '#99c7ff' : 'white' }}
        className="mr-3"
        as={'a'}
        href={leaderboardLink}>
        View Leaderboard
      </Button>
    );
  } else {
    ActionButton = (
      <Button kind="primary" className="mr-3" disabled>
        {registrationEnded
          ? 'Leaderboards Coming Soon!'
          : 'Registration Starts Soon!'}
      </Button>
    );
  }

  return (
    <TitleHero>
      <TitleWithImageContainer img={img}>
        <StyledPageTitle>{title}</StyledPageTitle>
        <Typography.Text style={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography.Text>
        <div>
          {showCertificateButton && (
            <Button
              className="mr-3"
              kind="primary"
              as="a"
              href={certificatePath}>
              Get Certificate
            </Button>
          )}

          {ActionButton}

          <Button
            kind="tertiary"
            as="a"
            style={{ color: '#99c7ff' }}
            href="/trading_challenges/faqs">
            Frequently Asked Questions
          </Button>

          {currentUser?.type === 'Professor' &&
            (registrationPeriodOpen || registrationEnded) && (
              <Button
                kind="tertiary"
                as="a"
                style={{ color: '#99c7ff' }}
                target="_blank"
                href={`/trading_challenges/${tradingChallengeId}/registrations_list`}>
                Team Registrations
              </Button>
            )}
        </div>
      </TitleWithImageContainer>
    </TitleHero>
  );
};

TradingChallengeTitleCard.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  img: string.isRequired,
  registrationLink: string.isRequired,
  registrationPeriodOpen: bool.isRequired,
};

const TradingChallengeSection = ({ title, children }) => {
  return (
    <div className="d-flex flex-no-wrap">
      <Typography.PageTitle style={{ width: '450px' }}>
        {title}
      </Typography.PageTitle>
      {children}
    </div>
  );
};
TradingChallengeSection.propTypes = {
  title: string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const TradingChallenge = ({
  previousDisplayStats,
  id,
  name,
  overview,
  description,
  registrationStart,
  registrationEnd,
  challengeStart,
  challengeEnd,
  startingPositionsDeadline,
  winnersAnnouncedDate,
  bannerImg,
  webinars,
  registrationLink,
  registrationPeriodOpen,
  peopleIconImg,
  teamsIconImg,
  tradesIconImg,
  registrationsCount,
  teamMembersCount,
  tradesCount,
  leaderboardLink,
  hasLeaderboards,
  currentUser,
  certificatePath,
}) => {
  const registrationEnded = new Date() > new Date(registrationEnd);
  const displayStats = hasLeaderboards
    ? {
        registrationsCount,
        teamMembersCount,
        tradesCount,
      }
    : previousDisplayStats;

  return (
    <ThemeProvider>
      <GlobalStyles />
      <TradingChallengeTitleCard
        title={name}
        description={overview}
        img={bannerImg}
        registrationLink={registrationLink}
        registrationPeriodOpen={registrationPeriodOpen}
        registrationEnded={registrationEnded}
        hasLeaderboards={hasLeaderboards}
        leaderboardLink={leaderboardLink}
        tradingChallengeId={id}
        currentUser={currentUser}
        certificatePath={certificatePath}
      />
      <Container className="mt-0">
        <TradingChallengeSection
          title={'More about the next Bloomberg Trading Challenge'}>
          <BfeGrid>
            <Row>
              <Typography.Text style={{ whiteSpace: 'pre-line' }}>
                {description}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.SubSectionTitle>Key Dates</Typography.SubSectionTitle>
            </Row>

            <Row between="xs">
              <Col xs={5} className="pl-0 pr-0">
                <Typography.SubSectionTitle>
                  Registration
                </Typography.SubSectionTitle>
                <Typography.Text>
                  <ClientDateTime date={registrationStart} /> -{' '}
                  <ClientDateTime date={registrationEnd} /> (New York Time EDT)
                </Typography.Text>
                <Typography.Hint>
                  *Teams must include: 1 team captain, 2-4 additional team
                  members, 1 faculty advisor
                </Typography.Hint>
              </Col>
              <Col xs={6} className="pl-0 pr-0">
                <Typography.SubSectionTitle>
                  Challenge start
                </Typography.SubSectionTitle>
                <Typography.Text>
                  <ClientDateTime date={challengeStart} /> (New York Time EDT)
                </Typography.Text>
              </Col>
            </Row>

            <br />

            <Row between="xs">
              <Col xs={5} className="pl-0 pr-0">
                <Typography.SubSectionTitle>
                  Starting positions entered no later than
                </Typography.SubSectionTitle>
                <Typography.Text>
                  <ClientDateTime date={startingPositionsDeadline} /> (New York
                  Time EDT)
                </Typography.Text>
              </Col>
              <Col xs={6} className="pl-0 pr-0">
                <Typography.SubSectionTitle>
                  Challenge end
                </Typography.SubSectionTitle>
                <Typography.Text>
                  <ClientDateTime date={challengeEnd} /> (New York Time EDT)
                </Typography.Text>
              </Col>
            </Row>

            <br />

            <Row>
              <Col className="pl-0 pr-0">
                <Typography.SubSectionTitle>
                  Winners announced
                </Typography.SubSectionTitle>
                <Typography.Text>
                  <ClientDate
                    date={winnersAnnouncedDate}
                    dateFormat={{
                      dateStyle: 'long',
                      timeZone: 'America/New_York',
                    }}
                  />
                </Typography.Text>
              </Col>
            </Row>

            <Row>
              <Typography.SubSectionTitle className="pr-2">
                Contact us for more info:
              </Typography.SubSectionTitle>
              <Typography.Link href="mailto:bbgtradecomp@bloomberg.net">
                bbgtradecomp@bloomberg.net
              </Typography.Link>
            </Row>
            <>
              <Row>
                <Button kind="primary" as="a" href="/trading_challenges/faqs">
                  Frequently Asked Questions
                </Button>
              </Row>
              <br />
              <Row style={{ fontSize: '1.5rem' }}>
                <Icon name="minimize" />
              </Row>
              <Row>
                <Button
                  kind={'tertiary'}
                  as="a"
                  target="_blank"
                  href="/trading_challenges/terms">
                  Terms and Conditions <Icon name="arrow-title" />
                </Button>
              </Row>
            </>
          </BfeGrid>
        </TradingChallengeSection>

        <MiddleSectionContainer>
          <TradingChallengeSection title={'Trading Challenge Updates'}>
            <BfeGrid>
              <Row>
                <Typography.SectionTitle>
                  {hasLeaderboards ? 'Our' : "Last Year's"} Numbers
                </Typography.SectionTitle>
              </Row>
              <Row>
                <StatCol xs={4}>
                  <img src={teamsIconImg} />
                  <Typography.Text>
                    <Typography.Numeric
                      value={displayStats.registrationsCount}
                    />{' '}
                    Teams
                  </Typography.Text>
                </StatCol>
                <StatCol xs={4}>
                  <img src={tradesIconImg} />
                  <Typography.Text>
                    <Typography.Numeric value={displayStats.tradesCount} />{' '}
                    trades entered
                  </Typography.Text>
                </StatCol>
                <StatCol xs={4}>
                  <img src={peopleIconImg} />
                  <Typography.Text>
                    <Typography.Numeric value={displayStats.teamMembersCount} />{' '}
                    people participating
                  </Typography.Text>
                </StatCol>
              </Row>

              {hasLeaderboards && (
                <>
                  <Row
                    style={{
                      fontSize: '1.5rem',
                      paddingTop: '1rem',
                      paddingBottom: '0.5rem',
                    }}>
                    <Icon name="minimize" />
                  </Row>
                  <Typography.SectionTitle>See More</Typography.SectionTitle>
                  <Row>
                    <Button kind={'tertiary'} as="a" href={leaderboardLink}>
                      View Leaderboard <Icon name="arrow-title" />
                    </Button>
                  </Row>
                </>
              )}
            </BfeGrid>
          </TradingChallengeSection>
        </MiddleSectionContainer>

        <Webinars webinars={webinars} />
      </Container>
    </ThemeProvider>
  );
};

TradingChallenge.propTypes = ShowProps;

export default TradingChallenge;
