import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

import { BfeGrid } from '../../styles/grid';

const StyledContainer = styled.div`
  background-color: white;
  padding: 2rem;
  min-height: 100%;
`;

const TextColorSpan = styled.span`
  color: ${({ theme }) => theme.colors.amber[600]})};
`;

const Show = ({
  registration,
  tradingChallenge,
  tradingChallengePath,
  downloadPath,
  linkedinImagePath,
}) => (
  <ThemeProvider>
    <GlobalStyles />
    <div className="bg-polka-dots">
      <BfeGrid>
        <Row>
          <Col xs={12} lg={8} lgOffset={2}>
            <StyledContainer>
              <Typography.LargeTitle>
                {tradingChallenge.name}
              </Typography.LargeTitle>

              <Typography.Text>
                Congratulations to team <strong>{registration.teamName}</strong>{' '}
                on participating in the{' '}
                <strong>
                  <TextColorSpan>{tradingChallenge.name}</TextColorSpan>
                </strong>
                ! For more details about the Bloomberg Global Trading Challenge,
                please visit the{' '}
                <Typography.Link href={tradingChallengePath}>
                  Trading Challenge details page
                </Typography.Link>
                .
              </Typography.Text>

              <object
                type="application/pdf"
                data={
                  `/trading_challenges/certificates/${registration.certificateUuid}/generate_preview` +
                  '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=Fit'
                }
                height="566"
                width="800"
              />

              <Typography.Text>
                Certificate ID:{' '}
                <TextColorSpan>{registration.certificateUuid}</TextColorSpan>
              </Typography.Text>

              <hr />

              <Button
                kind="primary"
                as="a"
                target="_blank"
                href={downloadPath}
                className="mr-3">
                Download
              </Button>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=portal.bloombergforeducation.com/certificate/${registration.certificateUuid}`}
                target="_blank"
                rel="nofollow noopener noreferrer">
                <Image
                  fluid
                  src={linkedinImagePath}
                  alt="LinkedIn Add to Profile button"
                />
              </a>
            </StyledContainer>
          </Col>
        </Row>
      </BfeGrid>
    </div>
  </ThemeProvider>
);

export default Show;
