import {
  Banner,
  GlobalStyles,
  NavigationBar,
  NotificationProvider,
  SubNav,
  ThemeProvider,
  TitleBar,
} from '@bbnpm/bb-ui-framework';
import { arrayOf, bool, oneOfType, shape, string } from 'prop-types';

import DeleteButton from './DeleteButton';
import PostButton from './PostButton';
import { UserProps } from './types';

const NavLinks = ({ links }) =>
  links.map(({ label, items, href, isActive }) =>
    label === 'Resources' ? (
      <span key={label}></span>
    ) : items ? (
      <NavigationBar.Menu active={isActive} label={label} key={label}>
        <SubNav>
          {items.map(({ label, href, isActive }) => (
            <SubNav.LinkWrapper key={label} header={label} active={isActive}>
              <a href={href}>{label}</a>
            </SubNav.LinkWrapper>
          ))}
        </SubNav>
      </NavigationBar.Menu>
    ) : (
      <NavigationBar.Link key={label} active={isActive}>
        <a href={href}>{label}</a>
      </NavigationBar.Link>
    ),
  );

const MainNav = ({
  isImpersonating,
  isAdmin,
  trueEmail,
  user,
  stopImpersonatingUsersPath,
  destroyUserSessionPath,
  newUserRegistrationPath,
  newUserSessionPath,
  profilePath,
  ordersPath,
  navLinks,
  adminNavLinks,
}) => (
  <ThemeProvider activeTheme="light">
    <GlobalStyles />
    <NotificationProvider>
      {isImpersonating && (
        <Banner
          message={`You ${trueEmail ? `(${trueEmail}) ` : ''}are logged in as ${
            user.firstName
          } ${user.lastName}`}
          link={
            <PostButton
              action={stopImpersonatingUsersPath}
              variant="warning"
              size="sm">
              Back to Admin
            </PostButton>
          }
        />
      )}
      <TitleBar
        productName="for Education"
        variant="dark"
        width="1440px"
        brandRenderer={({ children, ...rest }) => (
          <a href="/" {...rest}>
            {children}
          </a>
        )}>
        <TitleBar.Links>
          {!isAdmin &&
            user &&
            navLinks.find(link => link.label === 'Resources') && (
              <TitleBar.IconLink
                iconName="question-circle"
                text="Resources"
                menuItems={navLinks
                  .filter(link => link.label === 'Resources')[0]
                  .items.map(({ label, href }) => ({
                    label: label,
                    renderer: props => <a href={href} {...props} key={label} />,
                  }))}
              />
            )}
          <TitleBar.IconLink
            iconName="user"
            role="listitem"
            menuItems={
              isAdmin
                ? [
                    {
                      label: 'Administrator',
                      readonly: true,
                    },
                    {
                      label: 'Logout',
                      renderer: props => (
                        <DeleteButton
                          action={destroyUserSessionPath}
                          variant={false}
                          kind="primary"
                          {...props}
                        />
                      ),
                    },
                  ]
                : user
                  ? [
                      {
                        label: `${user.firstName} ${user.lastName}`,
                        readonly: true,
                      },
                      {
                        label: 'Profile',
                        renderer: props => <a href={profilePath} {...props} />,
                      },
                      {
                        label: 'Order History',
                        renderer: props => <a href={ordersPath} {...props} />,
                      },
                      TitleBar.IconLinkDivider,
                      {
                        label: 'Logout',
                        renderer: props => (
                          <DeleteButton
                            action={destroyUserSessionPath}
                            variant={false}
                            kind="primary"
                            {...props}
                          />
                        ),
                      },
                    ]
                  : [
                      {
                        label: 'Log In',
                        renderer: props => (
                          <a href={newUserSessionPath} {...props} />
                        ),
                      },
                      {
                        label: 'Sign Up',
                        renderer: props => (
                          <a href={newUserRegistrationPath} {...props} />
                        ),
                      },
                    ]
            }
          />
        </TitleBar.Links>
      </TitleBar>
      <NavigationBar width="1440px">
        <NavigationBar.Links>
          <NavLinks links={isAdmin ? adminNavLinks : navLinks} />
        </NavigationBar.Links>
      </NavigationBar>
    </NotificationProvider>
  </ThemeProvider>
);

const NavLinkProps = {
  label: string.isRequired,
  isActive: bool,
  href: string,
};

const NavLinkDropdownProps = {
  label: string.isRequired,
  items: arrayOf(shape(NavLinkProps)),
};

const NavLinksProps = arrayOf(
  oneOfType([shape(NavLinkProps), shape(NavLinkDropdownProps)]),
);

MainNav.propTypes = {
  isImpersonating: bool,
  trueEmail: string,
  stopImpersonatingUsersPath: string,
  destroyUserSessionPath: string.isRequired,
  newUserRegistrationPath: string.isRequired,
  newUserSessionPath: string.isRequired,
  profilePath: string,
  ordersPath: string.isRequired,
  rootPath: string.isRequired,
  isAdmin: bool,
  navLinks: NavLinksProps.isRequired,
  adminNavLinks: NavLinksProps,
  user: shape(UserProps),
};

NavLinks.propTypes = { links: NavLinksProps.isRequired };

export default MainNav;
