import { Button, Switch, Typography } from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import startcase from 'lodash.startcase';
import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { sendAsForm } from '../../../api';
import { create } from '../../../api';
import { BfeGrid } from '../../../styles/grid';
import { noop } from '../../../utils';
import { PageWrapper } from '../../CommonComponents';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import DeleteMemberCell from './DeleteMemberCell';
import EditModal from './EditModal';
import MemberModal, { UpdateMemberCell } from './MemberModal';
import { ShowProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Member Type', accessor: 'memberType' },
  {
    accessor: 'id',
    id: 'edit',
    disableSortBy: true,
    Cell: UpdateMemberCell,
  },
  {
    accessor: 'id',
    id: 'delete',
    disableSortBy: true,
    Cell: DeleteMemberCell,
  },
];

const cb = ({ totalCount, pageSize, teamMembers }) => ({
  data: teamMembers,
  pageSize,
  totalCount,
});

const Show = ({
  id,
  active,
  createdAt,
  teamName,
  teamMembers,
  adminTradingChallengePath,
  adminHref,
  pageSize,
  pageIndex,
  sortBy,
  totalCount,
  path,
  newMembersPath,
  institution,
  certificateUuid,
}) => {
  const [showEditModal, toggleShowEditModal] = useToggle(false);
  const [showMemberModal, toggleMemberModal] = useToggle(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState(teamName);
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(path, cb);

  const handleEdit = async data => {
    const res = await sendAsForm(adminHref, data, 'put');
    setName(res?.data?.teamName);
  };

  const handleAddSubmit = async values => {
    await create(newMembersPath, values)
      .then(() => {
        refreshData();
        toggleMemberModal();
      })
      .catch(error => {
        setErrors(error.response?.data?.errors);
      });
  };

  useEffect(() => {
    setErrors({});
  }, [showMemberModal]);

  // user (registrant) is not captain for high school users
  const captain = teamMembers.find(member => member.memberType === 'captain');

  return (
    <PageWrapper>
      <BfeGrid>
        <EditModal
          isOpen={showEditModal}
          toggleIsOpen={toggleShowEditModal}
          teamName={name}
          handleEdit={handleEdit}
        />
        <MemberModal
          isOpen={showMemberModal}
          toggleIsOpen={toggleMemberModal}
          newMembersPath={newMembersPath}
          registrationId={id}
          onSubmit={handleAddSubmit}
          errors={errors}
        />
        <Row>
          <Button
            as="a"
            kind="tertiary"
            icon="arrow-left"
            href={adminTradingChallengePath}>
            Back to Trading Challenge
          </Button>
        </Row>
        <Row className="d-flex align-items-center">
          <Typography.LargeTitle>
            <strong>Team Name: </strong>
            {name}
          </Typography.LargeTitle>
          <Button icon="pencil" className="ml-3" onClick={toggleShowEditModal}>
            Edit
          </Button>
        </Row>

        <Row>
          <Typography.Label>Created at: {createdAt}</Typography.Label>
        </Row>
        <Row>
          <Typography.Label>Certificate ID: {certificateUuid}</Typography.Label>
        </Row>
        <Row>
          <Switch
            defaultChecked={active}
            onChange={(_evt, isChecked) => {
              const data = new FormData();
              data.append('active', isChecked);
              handleEdit(data);
            }}
            label="Active"
          />
        </Row>

        <Row className="d-flex flex-column">
          <Typography.SectionTitle>
            <strong>Captain Info</strong>
          </Typography.SectionTitle>
          <div className="d-flex flex-wrap">
            <Col>
              <Typography.Text>
                <strong>ID: </strong>
                <a href={captain.user.href}>{captain.user.userid}</a>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>Name: </strong>
                {captain.user.fullName}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>UUID: </strong>
                {captain.user.uuid}
              </Typography.Text>
            </Col>
          </div>
        </Row>

        <Row className="d-flex flex-column">
          <Typography.SectionTitle>
            <strong>Institution Info</strong>
          </Typography.SectionTitle>
          <div className="d-flex flex-wrap">
            {institution ? (
              <>
                <Col>
                  <Typography.Text>
                    <strong>ID: </strong>
                    <a href={institution.href}>{institution.id}</a>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    <strong>Name: </strong>
                    {institution.name}
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    <strong>Type: </strong>
                    {startcase(institution.category)}
                  </Typography.Text>
                </Col>
              </>
            ) : (
              <Typography.Text>N/A</Typography.Text>
            )}
          </div>
        </Row>

        <Row className="d-flex justify-content-between pr-3">
          <Typography.SectionTitle>
            <strong>Team Members</strong>
          </Typography.SectionTitle>
          <Button kind="primary" onClick={toggleMemberModal}>
            Add Member
          </Button>
        </Row>
        <Table
          columns={columns}
          initialData={teamMembers}
          refreshData={refreshData}
          onRefreshDataFn={setRefreshDataFn}
          fetchData={fetchData}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
        />
      </BfeGrid>
    </PageWrapper>
  );
};

Show.propTypes = ShowProps;

export default Show;
