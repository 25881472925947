import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { useMemo, useState } from 'react';

import { noop } from '../../../utils';
import { StatusIcon } from '../../CommonComponents';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import RegistrationActionsCell from './RegistrationActionsCell';
import { RegistrationsTableProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryAdminKey },
  { Header: 'Team Name', accessor: 'teamName', filteredAs: 'string' },
  {
    Header: 'Registrant Name',
    accessor: 'user.fullName',
    disableSortBy: true,
  },
  {
    Header: 'Institution',
    accessor: 'institution.name',
    disableSortBy: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    disableSortBy: true,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: StatusIcon,
  },
  {
    accessor: 'id',
    id: 'actions',
    disableSortBy: true,
    Cell: RegistrationActionsCell,
  },
];

const cb = ({ totalCount, pageSize, registrations }) => ({
  data: registrations,
  pageSize,
  totalCount,
});

const RegistrationsTable = ({
  registrations,
  path,
  registrationTableProps,
  adminInstitutionsPath,
  newRegistrationPath,
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(path, cb);

  const additionalFilters = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'tradingChallengeTeamMembersEmail',
        filteredAs: 'string',
      },
      {
        Header: 'Institution',
        accessor: 'institutionId',
        filteredAs: 'autosuggest',
        path: adminInstitutionsPath,
      },
    ],
    [adminInstitutionsPath],
  );
  return (
    <>
      <Row className="mt-3 d-flex justify-content-between">
        <Col>
          <Typography.SectionTitle>Registrations</Typography.SectionTitle>
        </Col>
        <Col>
          <Button kind="primary" as="a" href={newRegistrationPath}>
            Add Registration
          </Button>
        </Col>
      </Row>

      <br />

      <Row>
        <Col xs={12}>
          <Table
            columns={columns}
            initialData={registrations}
            refreshData={refreshData}
            onRefreshDataFn={setRefreshDataFn}
            fetchData={fetchData}
            initialPageSize={registrationTableProps.pageSize}
            initialPageIndex={registrationTableProps.pageIndex}
            initialSortBy={registrationTableProps.sortBy}
            initialTotalCount={registrationTableProps.totalCount}
            additionalFilters={additionalFilters}
            initialScopes={{ isActive: false, isInactive: false }}
            availableScopes={[
              { id: 'isActive', title: 'Active' },
              { id: 'isInactive', title: 'Inactive' },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

RegistrationsTable.propTypes = RegistrationsTableProps;

export default RegistrationsTable;
