import { Typography } from '@bbnpm/bb-ui-framework';

import {
  ColoredText,
  StyledDataCell,
  StyledIcon,
  StyledTable,
  StyledTableHeader,
} from './styles';

export const ProfitLossCell = ({ value }) => {
  const sign = value < 0 ? '-' : value > 0 ? '+' : '';

  return (
    <ColoredText value={value}>
      {sign}$
      <Typography.Numeric value={Math.abs(value)} />
    </ColoredText>
  );
};

export const RelativeIcon = ({ prev, curr }) => {
  const rel = prev - curr;

  return (
    <StyledIcon
      name={rel < 0 ? 'chevron-down' : rel > 0 ? 'chevron-up' : 'minimize'}
      value={rel}
    />
  );
};

const LeaderboardTable = ({ items }) => {
  const headers = [
    'Global Rank',
    'Team',
    'Location',
    'Relative',
    'Absolute',
    'Prev Rank',
  ];
  return (
    <StyledTable>
      <StyledTableHeader>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </StyledTableHeader>
      <tbody>
        {items.map(
          ({
            id,
            globalRank,
            tradingChallengeRegistration,
            relativePl,
            absolutePl,
            lastWeekRank,
          }) => {
            const hasPrev = lastWeekRank !== '--';
            return (
              <tr key={id}>
                <td>{globalRank}</td>
                <td>
                  <strong>{tradingChallengeRegistration.teamName}</strong> (
                  {tradingChallengeRegistration.institution?.name || '*'})
                </td>
                <td>
                  {
                    tradingChallengeRegistration.institution?.countryCode
                      ?.countryName
                  }
                </td>
                <td>
                  <ProfitLossCell value={relativePl} />
                </td>
                <td>
                  <ProfitLossCell value={absolutePl} />
                </td>
                <td>
                  <StyledDataCell>
                    {lastWeekRank}{' '}
                    {hasPrev && (
                      <RelativeIcon prev={lastWeekRank} curr={globalRank} />
                    )}
                  </StyledDataCell>
                </td>
              </tr>
            );
          },
        )}
      </tbody>
    </StyledTable>
  );
};

export default LeaderboardTable;
